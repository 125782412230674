























import Bootstrapper from '@/app_code/Bootstrapping/Bootstrapper'
import { GetBlogModel } from '@/app_code/models/GetBlogModel'
import { API, ServiceManager } from '@/app_code/services'
import ServiceType from '@/app_code/services/ServiceTypes'
import { Mixins, Vue, Component, Prop } from 'vue-property-decorator'
import LinkCard from './LinkCard.vue'

@Component({
  components: {
    LinkCard
  }
})
export default class BlogPostCard extends Mixins(Vue) {
  @Prop({ required: true })
  private readonly blog!: GetBlogModel

  excerpt (html: string): string {
    const para = document.createElement('p')
    para.innerHTML = html
    return para.innerText.substr(0, 100).concat('...')
  }

  private api = ServiceManager.Require<API>(ServiceType.API)

  private getImageUrl (imageId: string): string {
    return `${Bootstrapper.Config?.API.Base}/api/files/${imageId}/download`
  }
}
