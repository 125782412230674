
























import { API, ServiceManager } from '@/app_code/services'
import { View } from '@/app_code/services/meta/view'
import ServiceType from '@/app_code/services/ServiceTypes'
import { Component, Mixins } from 'vue-property-decorator'
import { GetBlogModel } from '../app_code/models/GetBlogModel'
import { PagedResultModel } from '../app_code/models/PagedResultModel'
import BlogPostCard from '../components/cards/BlogPostCard.vue'
import Masonry from '../components/layout/Masonry.vue'
import Loader from '../components/ui/Loader.vue'

@Component({
  components: {
    Masonry,
    BlogPostCard,
    Loader
  }
})
export default class Blog extends Mixins(View) {
  private posts: PagedResultModel<GetBlogModel[]> | null = null

  private api = ServiceManager.Require<API>(ServiceType.API)

  mounted (): void {
    this.loadBlogs()
  }

  private loadBlogs (): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.api.BlogAPI.GetBlogs(1)
        .then(data => {
          this.posts = data
          resolve(true)
        })
        .catch(reject)
    })
  }
}
